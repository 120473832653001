/* src/styles/fonts.css */
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Black.woff2') format('woff2');
    font-weight: 900; /* Black */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Bold.woff2') format('woff2');
    font-weight: bold; /* Bold */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-ExtraBold.woff2') format('woff2');
    font-weight: 800; /* ExtraBold */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-ExtraLight.woff2') format('woff2');
    font-weight: 200; /* ExtraLight */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Light.woff2') format('woff2');
    font-weight: 300; /* Light */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Medium.woff2') format('woff2');
    font-weight: 500; /* Medium */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Regular.woff2') format('woff2');
    font-weight: normal; /* Regular */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-SemiBold.woff2') format('woff2');
    font-weight: 600; /* SemiBold */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Thin.woff2') format('woff2');
    font-weight: 100; /* Thin */
    font-style: normal;
  }
  
  /* 전역 스타일 적용 */
  body {
    margin: 0;
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-weight: normal;
  }
  