@use "src/utils/scss/media" as media;

.template {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  font-family: Pretendard;

  @include media.media-breakpoint(mobile) {
    flex-direction: column;
    height: auto;
    margin-top: 120px;
  }

  &__location {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  
    @include media.media-breakpoint(mobile) {
      width: 100vw;
      height: auto;
      margin-bottom: 20px;
    }
  }

  &__result {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  
    @include media.media-breakpoint(mobile) {
      width: 100vw;
      height: auto;
    }
  }
}

.location {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 300px 24px;

  @include media.media-breakpoint(mobile) {
    padding: 10px;
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  &__map {
    width: calc(50vw - 48px);
    height: calc(50vh - 48px);

    @include media.media-breakpoint(mobile) {
      width: calc(100vw - 48px);
      height: auto;
    }
  }

  &__reserve {
    text-decoration: none;
    color: #000;
    border: 1px solid #ccc;
    width: 200px;
    height: 36px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    background-color: aquamarine;
  }
}

.result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 300px 24px;

  @include media.media-breakpoint(mobile) {
    padding: 10px;
  }

  &__content {
    font-size: 36px;
    font-weight: 600;
  }

  &__subject {
    font-size: 24px;
    font-weight: 500;
  }

  &__description {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 20px;
  
    @include media.media-breakpoint(mobile) {
      width: calc(100vw - 40px);
      margin-bottom: 50px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__image {
    width: 300px;
    height: 300px;
  }

  &__title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  
    &--disabled {
      display: none;
    }
  
    @include media.media-breakpoint(mobile) {
      width: calc(100vw - 20px);
      margin-bottom: 50px;
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.species {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
}
