@use "src/utils/scss/media" as media;

.template {
  width: 100vw;
  height: auto;
  display: flex;
  background-color: #FFF;
  font-family: Pretendard;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column; 
    padding: 50px;
  }
}

.title {
  width: 100%;
  height: 100px;
  font-size: 36px;
  font-weight: 600;
}

.content {
  width: 100%;
  height: 100%;
  border-top: 2px solid #000;
  padding: 30px 0 0;

  &__list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: calc(100% - 20px);
    padding: 10px 4px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 500;
  
    &--reserve {
      border: 1px solid #000;
      height: 45px;
      margin: 24px 0;
      width: calc(100% - 20px);
      padding: 10px 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ccc;
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 500;
    }
  }

  &__reserve {
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-bottom: 4px solid rgba(0, 0, 0, 0.21);
    border-radius: 4px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
    background-color: #7fccde;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;

    &--disabled {
      background-color: #ccc;
      color: #000;
      opacity: 0.5;
    }
  }

  &__enter {
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-bottom: 4px solid rgba(0, 0, 0, 0.21);
    border-radius: 4px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
    background-color: greenyellow;
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;
    cursor: pointer;
  }
}