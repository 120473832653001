@use "src/utils/scss/media" as media;

.header {
  width: 100vw;
  height: 75px;
  background-color: #fefefe;
  font-family: Pretendard;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media.media-breakpoint(mobile) {
    width: 100vw; 
    height: 60px;
  }
}

.Logo {
  width: 10vw;
  font-size: 48px;
  font-weight: 800;
  margin-left: 40px;
  text-decoration: none;
  color: #000;

  @include media.media-breakpoint(mobile) {
    width: 20vw;
    font-size: 24px;
    margin-left: 20px;
  }
}

.item {
  width: calc(30vw - 56px);
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
  font-size: 28px;
  font-weight: 500;

  @include media.media-breakpoint(mobile) {
    width: calc(50vw - 36px);
    font-size: 20px;
    padding: 0 12px;
  }

  &__list {
    text-decoration: none;
    margin-right: 20px;
    color: #000;
    cursor: pointer;

    @include media.media-breakpoint(mobile) {
      margin-right: 10px;
    }
  }
}
