@use "src/utils/scss/media" as media;

.footer {
  width: 100vw;
  height: 200px;
  background-color: #3e3e3e;
  font-family: Pretendard;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sitemap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: Pretendard;

  @include media.media-breakpoint(mobile) {
    justify-content: center;
  }

  &__logo {
    display: inline-block;
    width: 72px;
    height: 100%;
    text-align: left;
    margin-right: 79px;

    & > img {
      width: 100%;
      height: 100%;
    }

    @include media.media-breakpoint(mobile) {
      order: 2;
      margin-right: 20px;
      width: 35px;
    }
  }

  &__content {
    display: flex;
    list-style: none;

    @include media.media-breakpoint(mobile) {
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__link {
    height: 30px;
    box-sizing: border-box;
    border-radius: 28px;
    border: solid 1px #9fa9b3;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: -0.1px;
    text-align: center;
    color: #c9c9c9;
    padding: 7px 15px;
    background-color: transparent;
    margin-right: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
      color: currentcolor;
      text-decoration: none;

      &:hover {
        font-weight: bold;
      }
    }

    @include media.media-breakpoint(mobile) {
      margin: 0;
      display: flex;
      align-items: flex-start;
      padding: 0 0 10px;
      border: 0;
      width: calc(50% - 8px);
      height: auto;
      font-size: 10px;
      font-weight: normal;
      line-height: 1.2;

      &:nth-child(2n + 1) {
        justify-content: right;

        &::after {
          position: absolute;
          margin-right: -9px;
          content: '|';
        }
      }

      &:nth-child(2n) {
        justify-content: left;
      }
    }
  }

  &__icon-links {
    @include media.media-breakpoint(mobile) {
      order: 4;
    }
  }

  &__icon-link {
    margin-right: 30px;

    & > img {
      height: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    @include media.media-breakpoint(mobile) {
      margin-right: 16px;

      & > img {
        height: 20px;
      }
    }
  }

  &__copyright {
    margin-top: 20px;
    width: 100%;
    font-family: NanumSquare, sans-serif;
    line-height: 1.08;
    letter-spacing: 0.15px;
    color: #b5b5b5;
    text-align: center;

    @include media.media-breakpoint(mobile) {
      order: 3;
      margin-right: 17px;
      margin-top: 0;
      width: auto;
      font-family: NanumSquare, sans-serif;
      font-size: 9px;
      font-weight: normal;
      line-height: 1.11;
      letter-spacing: -0.57px;
      text-align: left;
      color: #7d7d7d;
    }
  }
}
