@use "src/utils/scss/media" as media;

.template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;

  @include media.media-breakpoint(mobile) {
    width: 100vw;
  }
}

.template__video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 80%;
  height: 80vh;
  margin-bottom: 20px;

  @include media.media-breakpoint(mobile) {
    flex-direction: column;
    width: 100vw;
    height: 70vh;
  }
}

.template__remote,
.template__my {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 75%;
  margin: 10px;
  position: relative;

  @include media.media-breakpoint(mobile) {
    width: 100%;
    margin: 0;

    &.template__remote {
      height: 60%;
    }

    &.template__my {
      height: 30%;
    }
  }
}

.remoteVideo,
.myVideo {
  width: 100%;
  height: 75%;
  border: 2px solid white;

  @include media.media-breakpoint(mobile) {
    height: 100%;
  }
}

.template__title {
  color: white;
  margin-bottom: 10px;

  @include media.media-breakpoint(mobile) {
    font-size: 1rem;
    margin-bottom: 5px;
  }
}

.template__chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-bottom: 20px;
}

.loadingText,
.noSignalText {
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include media.media-breakpoint(mobile) {
    font-size: 1em;
  }
}

.disconnectButton {
  position: absolute;
  bottom: 20px;

  @include media.media-breakpoint(mobile) {
    bottom: 10px;
    font-size: 0.8em;
  }
}

.diagnosisForm {
  width: 80%;
  padding: 20px 10%;
  margin: 20px 0;
  background-color: #fff;
  color: #000;

  @include media.media-breakpoint(mobile) {
    width: 90%;
    padding: 10px 5%;
    margin: 10px 0;
  }
}

.submitButton {
  margin-top: 10px;

  @include media.media-breakpoint(mobile) {
    font-size: 0.8em;
    margin-top: 5px;
  }
}
