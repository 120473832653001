@use "src/utils/scss/media" as media;

.section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;

  @include media.media-breakpoint(mobile) {
    flex-direction: column;
    margin-top: 120px;
    width: 100vw;
    height: auto;
    margin-bottom: 80px;
  }

  &__context {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Pretendard;
  
    @include media.media-breakpoint(mobile) {
      width: 100vw;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Pretendard;
    font-weight: 800;
    font-size: 48px;
    margin-bottom: 40px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Pretendard;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
  }

  &__upload {
    margin-top: 20px;
    font-family: Pretendard;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    height: 100px;

    @include media.media-breakpoint(mobile) {
      width: 100vw;
      height: auto;
    }

    label {
      cursor: pointer;
      background-color: #e0e0e0;
      padding: 10px 20px;
      border-radius: 8px;
      display: inline-block;
      margin-bottom: 12px;
    }

    input[type="file"] {
      display: none;
    }
  }
}

.logo {
  width: 400px;
  height: 400px;
}

.file {
  height: 300px;
  width: 300px;
  border: 2px dashed #007bff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 120px 40px;
  margin: 20px auto;
  cursor: pointer;

  @include media.media-breakpoint(mobile) {
    padding: 40px 40px;
    width: 175px;
    height: 175px;
  }
}

.preview {
  display: flex;
  width: 240px;
  height: 750px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media.media-breakpoint(mobile) {
    padding: 40px 40px;
    width: 175px;
    height: 175px;
  }

  &__image {
    max-width: 240px;
    max-height: 240px;
  }

  &__remove {
    cursor: pointer;
    background-color: #ff000f;
    width: 100px;
    margin-top: 5px;
    color: #fbfbfb;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 12px;
  }
}

.submit {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;

  &--able {
    cursor: pointer;
    background-color: #3cb371;
    color: white;
  }
}

.background {
  width: 200vw;
  height: 200vh;
  position: absolute;
  margin-top: -120px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
