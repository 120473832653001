@use "src/utils/scss/media" as media;

.template {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 697px;
  background-color: #FFF;
}

.title {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 300px;
  height: 450px;
}

.input {
  margin-bottom: 40px;
}

.submit {
  margin-top: 20px;
}

.register {
  text-decoration: none !important;
}